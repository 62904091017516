import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import { MDXRenderer } from 'gatsby-plugin-mdx';

const Service = ({ data }) => {
  const { seoTitle } = data.mdx.frontmatter;
  const { seoDesc } = data.mdx.frontmatter;
  const { body } = data.mdx.body;
  return (
    <Layout bodyClass="page-service">
      <SEO title={seoTitle} description={seoDesc} />
      <div className="content">
        <div className="container22">
          <div className="row justify-content-start">
            <div className="col-12">
              <div className="content service service-single">
                <MDXRenderer>{data.mdx.body}</MDXRenderer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        seoTitle
        seoDesc
        path
      }
      body
    }
  }
`;

export default Service;
